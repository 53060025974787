import { AuthConfig } from 'angular-oauth2-oidc';

const authConfig: AuthConfig = {
  issuer: 'https://auth.sbx.laterpay.net/',
  redirectUri: window.location.origin, // allowed (NB: https!): https://localhost:4200 https://dev-contribute.de
  clientId: 'client.5b24212d-b9d9-4ae6-b6f6-461830ae2a77',
  responseType: 'code',
  scope: 'read write',
  oidc: false,
  // This is needed for silent refresh (refreshing tokens w/o a refresh_token)
  // **AND** for logging in with a popup
  silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
  useSilentRefresh: false,
  showDebugInformation: true,
  sessionChecksEnabled: true,
  timeoutFactor: 0.01,
  clearHashAfterLogin: false,
  customQueryParams: {
    audience: 'laterpay',
  },
};

export const environment = {
  maintenance: false,
  production: true,
  testMode: true,
  authConfig,
  googleAnalyticsId: 'UA-50448165-28',
  url: {
    tapper: 'https://tapi.sbx.laterpay.net',
    signon: 'https://signon.sbx.contribute.to',
  },
  blacklist: {
    slugs: [],
  },
  tokenDomains: [
    'frontend.sbx.contribute.to',
    'dev-activatecamera.de',
    'dev-contribute.de/api/v1',
    'localhost:4200/api/v1',
    '/api/v1',
    'localhost:4040',
    'activate.camera',
    'laterpay.net',
  ],
  mailchimp: {
    host: 'https://contribute.us7.list-manage.com',
    u: '6d7a6446cc76e5b7db6716a61',
    id: '9ea4866524',
    hidden: 'b_6d7a6446cc76e5b7db6716a61_9ea4866524',
  }
};
