export interface Artist {
  name?: string;
  description?: string;
  avatar?: string;
  images?: string[];
  video?: string;
  slug?: string;
  realSlug?: string;
  realCard?: string;
  matcher?: {
    name: string;
  }
  social?: {
    website?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    linkedin?: string;
    tiktok?: string;
  },
  collection?: {
    color: string;
    textColor?: string;
    description: string;
    logo: string;
    name: string;
    matchingMessage?: string;
    readMore?: string;
  }
  merchant_id?: string;
  endorser?: {
    name?: string;
    url?: string;
    image?: string;
    customText?: string;
  }
}


export interface ArtistData {
  [key: string]: Artist;
}

export const ARTISTS: ArtistData = {
  'blaire-old': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our differences. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the production costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blairehawes-artist/images/image01.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image02.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image03.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image04.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image05.jpeg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com/',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    }
  }
};

export const SHARED: ArtistData = {
  'blaire-starbucks': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our differences. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the production costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blairehawes-artist/images/image01.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image02.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image03.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image04.jpeg',
      '/assets/data/artists/blairehawes-artist/images/image05.jpeg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com/',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#007042',
      logo: '/assets/data/artists/blairehawes-artist/images/starbucks-logo.png',
      name: 'Starbucks',
      description: 'Be the change you want to see in the world. Join Starbucks in supporting creators working to make a difference through their art.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      name: 'Carrie Scott',
      url: '/carriescott',
      image: 'https://contribute.to/cdn/6156fc64b4e1480012efc465',
    }
  },
  'blaire': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our difference. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the productions costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blaire/images/Blaire Hawes 0.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 1.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 2.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 3.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 4.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 5.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 6.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 7.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 8.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 9.jpg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#54A2D3',
      logo: 'https://contribute.to/cdn/618e8fc4b4e1480012f7d038',
      name: 'Nicola Erni',
      matchingMessage: 'Nicola Erni will double your contribution to this artist.',
      description: 'Nicola Erni is an entrepreneur and art collector who has followed her passion for the past twenty years by supporting artists in many ways.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      name: 'Carrie Scott',
      url: '/carriescott',
      image: 'https://contribute.to/cdn/6156fc64b4e1480012efc465',
    }
  },
  'blaire-alfred': {
    name: 'Blaire Hawes',
    description: `I am interested in people: in their lives, their dreams, their spirit. I take pictures to tell some small part of their story, by the way I frame my compositions intimately, in close focus. With Contribute.to I would like to create a portrait of “A Human” that is made up of hundreds of my portraits. This composite will be made up of people of all ages, races, ability, and sexes. I am doing this to show how we are all connected and related even in our difference. What’s more I will stitch the portraits physically together - like a quilt - to make one single work out of it. <br><br> My goal is to exhibit this work publicly and to raise funds for the productions costs. I also get my daughters to help me on shoots, and I always pay them. They need to know they don’t ever work for free. Please back me!`,
    avatar: 'https://contribute.to/cdn/615c6fe0b4e1480012efdb83',
    images: [
      '/assets/data/artists/blaire/images/Blaire Hawes 0.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 1.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 2.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 3.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 4.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 5.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 6.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 7.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 8.jpg',
      '/assets/data/artists/blaire/images/Blaire Hawes 9.jpg',
    ],
    video: 'https://contribute.to/cdn/creator_videos/Blaire_Vertical_compressed.mp4',
    social: {
      website: 'https://www.imagesbyblairecatherine.com',
      instagram: 'https://www.instagram.com/imagesbyblairecatherine/',
    },
    realSlug: 'blairehawes',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#5d81e6',
      logo: 'https://app.contribute.to/cdn/60f57524ddd7d300125a7308',
      name: 'Alfred G. Goldstein',
      matchingMessage: 'Alfred will double your contribution to this artist.',
      description: 'Alfred G. Goldstein believes that the measure of the success of a society is the quality of its culture. Alfred is also co-founder of “Project Hope” with his',
      readMore: ' sons, Mark and Robert, in memory of his wife and their mother, Hope Perry Goldstein. The mission of Project Hope is to reduce the incidence of ovarian cancer and improve outcomes for women treated for it as a result of research and education.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      name: 'Carrie Scott',
      url: '/carriescott',
      image: 'https://contribute.to/cdn/6156fc64b4e1480012efc465',
    }
  },
  'thedesignmuseum': {
    name: 'Blaire Hawes',
    realCard: 'theword',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#ede85f',
      textColor: '#000',
      logo: '/assets/data/artists/thedesignmuseum/images/design-museum-logo.png',
      name: 'The Design Museum',
      description: 'We are proud to be partnering with Beazley who has generously provided up to $50k to support The Word. In order to unlock these funds, we need your support. For every $100 you give, the sponsor gives another $100.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      customText: 'Sponsored by Beazley who will double your contribution.',
      image: '/assets/data/artists/thedesignmuseum/images/beazley-logo.png',
    }
  },
  'nortonmuseum': {
    name: 'Blaire Hawes',
    realCard: 'nortonmuseumofart',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#5d81e6',
      textColor: '#fff',
      logo: '/assets/data/artists/nortonmuseum/images/logo.png',
      name: 'Norton Museum of Art',
      description: 'The Norton Museum of Art was founded in 1941 by Ralph Hubbard Norton and his wife Elizabeth Calhoun Norton. They understood that communities can’t reach their potential without vibrant cultural institutions.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      customText: 'Sponsored by the generous support of the Nicola Erni Collection.',
      image: 'https://contribute.to/cdn/618e8fc4b4e1480012f7d038',
    }
  },
  'norton': {
    name: 'Blaire Hawes',
    realCard: 'nortonart',
    matcher: {
      name: 'Nicola Erni',
    },
    collection: {
      color: '#5d81e6',
      textColor: '#fff',
      logo: '/assets/data/artists/nortonmuseum/images/logo.png',
      name: 'Norton Museum of Art',
      description: 'The Norton Museum of Art was founded in 1941 by Ralph Hubbard Norton and his wife Elizabeth Calhoun Norton. They understood that communities can’t reach their potential without vibrant cultural institutions.',
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      customText: 'Your contribution will be matched by “insert sponsor here.”',
      image: '/assets/data/artists/nortonmuseum/images/logo.png',
    }
  },
  'jkw-wrapped': {
    name: 'Justin Warren',
    realCard: 'jkw',
    matcher: {
      name: 'Ebert Monk',
    },
    collection: {
      color: '#5d81e6',
      textColor: '#fff',
      logo: '/assets/data/artists/nortonmuseum/images/logo.png',
      name: 'Jimmy Johns',
      description: 'Jimmy Johns tastes great',
      readMore: ' extra text',
      matchingMessage: 'Ebert might match your donation. Who knows'
    },
    merchant_id: 'merchant.ebee3b65-196a-4d5d-8fbb-4370f61b2c80',
    endorser: {
      customText: 'Your contribution will be matched by “Ebert Monk”',
      image: '/assets/data/artists/nortonmuseum/images/logo.png',
    }
  },
};
